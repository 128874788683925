import React from 'react'
import { graphql } from 'gatsby'
import Layout from '../../components/layout'
import PageBuilder from '../../components/common/PageBuilder/PageBuilder'
import PageIntro from '../../components/common/PageIntro'
import { Helmet } from 'react-helmet'

export const query = graphql`
	query ArticleQuery($Slug: String!) {
		strapiPages(Slug: { eq: $Slug }) {
			Slug
			Title
			Paragraph
			pageBuilder
			HeroImage {
				provider_metadata {
					public_id
				}
				localFile {
					childImageSharp {
						fluid {
							base64
							tracedSVG
							srcWebp
							srcSetWebp
							originalImg
							originalName
						}
					}
				}
				formats {
					medium {
						url
					}
					small {
						url
					}
					thumbnail {
						url
					}
				}
			}
			MetaTitle
			MetaKeywords
			MetaDescription
			MetaImage {
				formats {
					small {
						url
					}
				}
			}
		}
	}
`

const Article = ({ data }) => {
	const pageData = data.strapiPages
	console.log(data)
	return (
		<Layout>
			<Helmet>
				<title>Delta Underwriting | {pageData.Title}</title>
				{pageData.MetaTitle != null ? (
					<meta property="og:title" content={pageData.MetaTitle} />
				) : (
					<meta name="og:title" content={pageData.Title} />
				)}
				{pageData.MetaDescription != null ? (
					<meta name="description" content={pageData.MetaDescription} />
				) : (
					<meta name="title" content={pageData.Description} />
				)}
				{pageData.MetaKeywords != null ? (
					<meta name="keywords" content={pageData.MetaKeywords} />
				) : null}
				{pageData.MetaImage != null ? (
					<meta
						property="og:image"
						content={pageData.MetaImage.formats.small.url}
					/>
				) : null}
			</Helmet>
			<h1 class="visually-hidden">{pageData.Title}</h1>
			<PageIntro
				title={pageData.Title}
				summary={pageData.Paragraph}
				image={pageData.HeroImage}
			/>
			<PageBuilder data={pageData.pageBuilder} />
		</Layout>
	)
}

export default Article
